import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import Ast from '../components/ast'
import Heading from '../components/page-heading'

export default ({ data: { allContentfulAboutPage: { edges } } }) => {
  const { node } = edges[0]
  const { title, body } = node
  const htmlAst = body.childMarkdownRemark.htmlAst

  return (
    <Layout>
      <Heading>{title}</Heading>
      <Ast ast={htmlAst} />
    </Layout>
  )
}

export const pageQuery = graphql`
  query AboutPage {
    allContentfulAboutPage {
      edges {
        node {
          title
          path {
            url
            label
          }
          body {
            childMarkdownRemark {
              html
              htmlAst
            }
          }
        }
      }
    }
  }
`
